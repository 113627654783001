import * as React from 'react'
import DefaultLayout from "../layouts/default";
import {getDefaultTitle} from "../logic/seo";
import {Helmet} from "react-helmet";

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
    public render() {
        if (typeof window !== `undefined`) {
            const paperform = require("../../static/js/paperform")
        }
        return (
            <div>
                <Helmet>
                    <title>{getDefaultTitle("Fehler melden")}</title>
                    <meta name="description" content="Melden Sie uns Fehler auf unserer Seite."/>

                </Helmet>
                <DefaultLayout>
                    <div className="bg-image" style={{
                        backgroundImage: "url(images/christmas.jpg)",
                        backgroundSize: "100%",
                        height: "25vh"
                    }}
                    >
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>Shop melden</h1>
                                <p>Aktuell befinden wir uns noch im Aufbau unserer Plattform und freuen uns über Ihre
                                    Vorschläge zu entsprechenden Online-Shops.</p>
                                <div data-prefill-inherit="1" data-paperform-id="hvsrxdzy"></div>
                            </div>



                        </div>
                    </div>


                </DefaultLayout>
            </div>

        )
    }
}